import {ICashierPaymentsApi, PaymentMethod} from '@wix/cashier-payments-widget';
import {CashierMandatoryField, CashierMandatoryFieldsOverrides} from '../../types/app.types';
import {CheckoutModel} from '../models/Checkout.model';
import {getCountryFromLocaleDataset} from './localeDataset.util';

const MANDATORY_FIELDS_PLACEHOLDER = '-';

export function getMandatoryFieldsByCheckout(checkout: CheckoutModel) {
  const country = checkout.billingInfo?.address?.country ?? checkout.billingInfo?.address?.country;
  const countryCode = country ? getCountryFromLocaleDataset(country)?.key : MANDATORY_FIELDS_PLACEHOLDER;
  return {
    countryCode,
    email: checkout.buyerInfo.email ?? MANDATORY_FIELDS_PLACEHOLDER,
    state: checkout.billingInfo?.address?.subdivision ?? MANDATORY_FIELDS_PLACEHOLDER,
    firstName: checkout.billingInfo?.contact?.firstName ?? MANDATORY_FIELDS_PLACEHOLDER,
    lastName: checkout.billingInfo?.contact?.lastName ?? MANDATORY_FIELDS_PLACEHOLDER,
    street: checkout.billingInfo?.address?.streetAddress?.name ?? MANDATORY_FIELDS_PLACEHOLDER,
    houseNumber: checkout.billingInfo?.address?.streetAddress?.number ?? MANDATORY_FIELDS_PLACEHOLDER,
    address: checkout.billingInfo?.address?.addressLine ?? MANDATORY_FIELDS_PLACEHOLDER,
    city: checkout.billingInfo?.address?.city ?? MANDATORY_FIELDS_PLACEHOLDER,
    zipCode: checkout.billingInfo?.address?.postalCode ?? MANDATORY_FIELDS_PLACEHOLDER,
    phone: checkout.billingInfo?.contact?.phone ?? MANDATORY_FIELDS_PLACEHOLDER,
  };
}

export const cashierWidgetEwalletPaymentMethod: string[] = [PaymentMethod.PayPal, PaymentMethod.GooglePay];

export const updateMandatoryFields = async (
  paymentsApi: ICashierPaymentsApi | undefined,
  setCashierMandatoryFields: (cashierMandatoryFields: CashierMandatoryFieldsOverrides) => void,
  country?: string | null
): Promise<void> => {
  //@ts-expect-error cashier api
  const mandatoryFields: string[] = (await paymentsApi?.fetchMandatoryFields(country)) ?? [];
  const cashierMandatoryFieldsOverrides: CashierMandatoryFieldsOverrides = {};

  const formattedCashierMandatoryFields =
    mandatoryFields
      ?.map((field) => {
        return CashierMandatoryField[field as CashierMandatoryField];
      })
      .filter((field) => Boolean(field)) ?? /* istanbul ignore next */ [];

  formattedCashierMandatoryFields.forEach((field) => {
    cashierMandatoryFieldsOverrides[field] = true;
  });

  setCashierMandatoryFields(cashierMandatoryFieldsOverrides);
};
